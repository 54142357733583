<template>
  <div
    class="text-xs md:text-md wrapper px-2 md:px-12 grid grid-cols-2 gap-x-2 gap-y-1 md:gap-x-4 gap-y-2"
  >
    <div class="col-span-2 grid gap-8" @click="toggleActiveAndShowDescription(0)">
      <div :class="['cursor-pointer bg-black text-white', { 'bg-selected-pent': isActive(0) }]">
        <div class="flex flex-row items-center border-b-[2px] border-white py-4 px-2 md:px-6">
          <p>{{ apartments[0].rooms }} ROK</p>
          <span class="w-[6px] bg-white h-[1px] mx-1"></span>
          <p>VÅN {{ apartments[0].floor }}</p>
          <span class="w-[6px] bg-white h-[1px] mx-1"></span>
          <p>{{ apartments[0].size }} KVM</p>
        </div>
        <ul class="usp grid grid-cols-2">
          <li
            v-for="item in apartments[0].bullet_list"
            :key="item"
            class="py-3 px-2 md:px-6 border-t-[0.5px] border-white"
          >
            {{ item.text }}
          </li>
        </ul>
        <div class="grid grid-cols-2 px-6 pb-8 pt-12">
          <div class="col-span-1 col-start-2 pl-2">
            <img :src="img + '/img/apartment/ersta-1.png'" alt="" class="w-full" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDescriptionVisible(0)" class="text-white bg-black col-span-2">
      <div class="grid grid-cols-6 border-b-[3px] border-white py-4">
        <div class="col-span-2">
          <p class="uppercase py-2 px-4 md:px-6 border-b-[0.5px] border-white">namn</p>
          <p class="uppercase py-2 px-4 md:px-6 border-b-[0.5px] border-white">rum</p>
          <p class="uppercase py-2 px-4 md:px-6 border-b-[0.5px] border-white">storlek ca.</p>
          <p class="uppercase py-2 px-4 md:px-6 border-b-[0.5px] border-white">våning</p>
          <p class="uppercase py-2 px-4 md:px-6 border-b-[0.5px] border-white">avgift</p>
          <p class="uppercase py-2 px-4 md:px-6">pris</p>
        </div>
        <div class="col-span-4">
          <p class="border-b-[0.5px] border-white py-2 px-4 md:px-6">
            {{ apartments[0].tagline }}/25
          </p>
          <p class="border-b-[0.5px] border-white py-2 px-4 md:px-6">
            {{ apartments[0].rooms }} rok
          </p>
          <p class="border-b-[0.5px] border-white py-2 px-4 md:px-6">
            {{ apartments[0].size }} kvm
          </p>
          <p class="border-b-[0.5px] border-white py-2 px-4 md:px-6">{{ apartments[0].floor }}</p>
          <p v-if="apartments[0].fee" class="border-b-[0.5px] border-white py-2 px-4 md:px-6">
            {{ apartments[0].fee }}
          </p>
          <p v-else class="border-b-[0.5px] border-white py-2 px-4 md:px-6">Publiceras senare</p>
          <p class="py-2 px-4 md:px-6">Fås på begäran</p>
        </div>
      </div>
      <div class="pt-6 penthouse">
        <div class="border-b-[3px] border-white">
          <h3 class="uppercase pb-6 px-4 md:px-6">Om bostaden 1/25</h3>
          <div class="flex flex-col gap-8 pb-16 px-4 md:px-6">
            <p>
              Hela bostaden har varmt beiga kalk- och lerputsade väggar och ljusa vackra ekgolv.
              Våningens tre sovrum har alla egna, separata badrum inredda med dusch och toalett.
              Stilfulla materialval så som marmorino, terazzo och röd kalksten är exempel från det
              exklusiva masterbadrummet som även har bastu med panel i cederträ och fristående
              badkar. Köket och vardagsrummet möts i en öppen genomarbetad planlösning med
              imponerande utsikt åt samtliga väderstreck.
            </p>
            <p>
              Platsbyggda bokhyllor i våningens bibliotek, ett inbyggt tv-skåp i vardagsrummet täckt
              med dekorativ tapet och en fullt inredd walk-in-closet är exempel på smarta och
              exklusiva lösningar som visar att bostadens alla utrymmen utnyttjas fullt ut med
              skräddarsydda lösningar. Pricken över i:et är den eleganta gaseldstad som blir en
              naturlig social mittpunkt med sin placering mot utsikten över Saltsjön i
              vardagsrummet.
            </p>
          </div>
        </div>
        <div class="px-4 md:px-6">
          <p class="uppercase py-8">bofaktablad</p>
          <img
            :src="apartments[0].floorplan.url"
            alt="Floor Plan"
            class="border-[0.5px] border-black pb-8 md:pb-24"
          />
          <div class="pt-4 pb-12">
            <a :href="apartments[0].sheet.url" class="link" target="_blank"
              >Ladda ner bofaktablad</a
            >
          </div>
        </div>
        <div v-if="apartments[0].image_sm" class="w-full pt-8 pb-10 border-t-[3px] border-white">
          <p class="px-5 uppercase pb-6">Utsikt</p>
          <img :src="apartments[0].image_sm.url" :alt="apartments[0].image_sm.url" />
        </div>
        <div class="px-5 pb-8">
          <a href="https://www.erstapenthouse.se/" target="_blank"
            ><button class="btn">penthouse</button></a
          >
        </div>
      </div>
    </div>
    <template v-for="(item, i) in apartments.slice(1)" :key="i">
      <div
        :class="[
          'bg-white card flex flex-col justify-between cursor-pointer',
          { 'bg-selected': isActive(i + 1) },
        ]"
        @click="toggleActiveAndShowDescription(i + 1)"
      >
        <div class="pb-2">
          <div
            class="border-b-[3px] border-black flex flex-row px-2 md:px-6 pt-4 pb-3 items-center w-full"
          >
            <p>{{ item.rooms }} ROK</p>
            <span class="w-[6px] bg-black h-[1px] mx-1"></span>
            <div v-if="item.floor === '0'" class="col-span-2">
              <p class="">Entréplan</p>
            </div>
            <div v-else class="col-span-2">VÅN {{ item.floor }}</div>
            <span class="w-[6px] bg-black h-[1px] mx-1"></span>
            <p>{{ item.size }} KVM</p>
          </div>
          <div v-if="item.bullet_list" class="usp">
            <div
              v-for="tagline in item.bullet_list"
              :key="tagline"
              class="tagline px-2 md:px-6 py-3"
            >
              {{ tagline.text }}
            </div>
          </div>
        </div>
        <div
          :id="'info-' + (i + 1)"
          ref="info-{{ i + 1 }}"
          class="flex flex-row justify-end pr-4 w-full pt-6 pb-4"
        >
          <img
            :src="img + '/img/apartment/ersta-' + item.tagline + '.png'"
            alt="Planritning av bostad"
            class="max-w-[120px]"
          />
        </div>
      </div>
      <div
        v-if="(i + 1) % 2 === 0 || i === apartments.slice(1).length - 1"
        class="grid grid-cols-2 col-span-2"
      >
        <div v-if="isDescriptionVisible(i + 1)" class="pb-8 col-span-2 pt-2">
          <div class="bg-white grid grid-cols-6 border-t-[3px] border-black">
            <div v-if="apartments[selectedIndex].is_sold" class="col-span-6">
              <p class="uppercase border-b-[2px] border-black py-2 px-4 md:px-6">
                DENNA BOSTAD ÄR BOKAD
              </p>
            </div>
            <div class="col-span-2" :class="{ 'opacity-35': apartments[selectedIndex].is_sold }">
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6 uppercase">namn</p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6 uppercase">rum</p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6 uppercase">storlek ca.</p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6 uppercase">våning</p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6 uppercase">avgift</p>
              <p class="border-t-[0.5px] border-black pt-2 pb-4 px-4 md:px-6 uppercase">pris</p>
            </div>
            <div class="col-span-4" :class="{ 'opacity-35': apartments[selectedIndex].is_sold }">
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                {{ apartments[selectedIndex].tagline }}/25
              </p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                {{ apartments[selectedIndex].rooms }} rok
              </p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                {{ apartments[selectedIndex].size }} kvm
              </p>
              <p
                v-if="apartments[selectedIndex].floor === '0'"
                class="border-t-[0.5px] border-black py-2 px-4 md:px-6"
              >
                Entréplan
              </p>
              <p v-else class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                {{ apartments[selectedIndex].floor }}
              </p>
              <p
                v-if="apartments[selectedIndex].fee"
                class="border-t-[0.5px] border-black py-2 px-4 md:px-6"
              >
                {{ apartments[selectedIndex].fee }}
              </p>
              <p v-else class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                Publiceras senare
              </p>
              <p class="border-t-[0.5px] border-black py-2 px-4 md:px-6">
                {{ apartments[selectedIndex].price }}
              </p>
            </div>
          </div>
          <div
            class="bg-white px-4 md:px-6 border-t-[3px] border-black"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <p class="uppercase py-4">Om bostaden {{ apartments[selectedIndex].tagline }}/25</p>
            <div
              v-if="apartments[selectedIndex].about"
              class="wysiwyg pb-12"
              v-html="apartments[selectedIndex].about"
            ></div>
          </div>
          <div
            class="bg-white px-4 md:px-6 border-t-[3px] border-black"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <p class="uppercase pt-4 pb-6">Bokfaktablad</p>
            <img
              :src="apartments[selectedIndex].floorplan.url"
              alt="Floor Plan"
              class="border-[0.5px] border-black"
            />
          </div>
          <div
            class="bg-white pt-6 pb-12 px-4 md:px-6 border-b-[3px] border-black"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <a :href="apartments[selectedIndex].sheet.url" class="link" target="_blank"
              >Ladda ner bofaktablad</a
            >
          </div>
          <div
            v-if="apartments[selectedIndex].image_sm"
            class="w-full pt-8 pb-10 bg-white border-b-[3px] border-black"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <p class="px-5 uppercase pb-6">Utsikt</p>
            <img
              :src="apartments[selectedIndex].image_sm.url"
              :alt="apartments[selectedIndex].image_sm.url"
            />
            <a href="" class="px-5"><button class="btn mt-8">intresseanmälan</button></a>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['img', 'apartments'],
  data() {
    return {
      selectedIndex: null,
      activeIndex: null
    }
  },
  mounted() {
    const savedIndex = sessionStorage.getItem('selectedApartmentIndex')
    if (savedIndex !== null) {
      this.selectedIndex = parseInt(savedIndex)
      this.$nextTick(() => {
        this.scrollToDescription(this.selectedIndex)
      })
    }
  },
  methods: {
    toggleActive(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
    isActive(index) {
      return this.activeIndex === index
    },
    showDescription(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = null
      } else {
        this.selectedIndex = index
        this.$nextTick(() => {
          this.scrollToDescription(index)
        })
      }
    },
    isDescriptionVisible(index) {
      if (index === 0) {
        return this.selectedIndex === 0
      } else {
        const isEven = index % 2 === 0
        const isFirstInPair = index === 1 || index % 2 === 1
        return (
          (this.selectedIndex !== null &&
            isEven &&
            Math.floor((this.selectedIndex - 1) / 2) === Math.floor((index - 1) / 2)) ||
          (!isEven &&
            isFirstInPair &&
            Math.floor((this.selectedIndex - 1) / 2) === Math.floor((index - 1) / 2))
        )
      }
    },
    scrollToDescription(index) {
      const element = document.getElementById('info-' + index)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    toggleActiveAndShowDescription(index) {
      this.toggleActive(index)
      this.showDescription(index)
    },
    getSelectedTagline() {
      const selectedIndex = parseInt(sessionStorage.getItem('selectedApartmentIndex'))
      if (!isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < this.apartments.length) {
        return this.apartments[selectedIndex].tagline
      }
      return ''
    }
  }
}
</script>

<style scoped>
.stroke {
  @apply w-[10px] bg-black h-[2px] mx-2;
}
.card {
  min-height: 338px;
}
.bg-selected {
  @apply bg-beige;
}
.bg-selected-pent {
  background-color: #3f3f3f;
}
</style>
