<template>
  <div :class="{ hidden: success }">
    <h1 class="heading-md pb-6">Intresseanmälan</h1>
    <p class="md:w-1/2 md:pr-6">
      Fyll i dina kontaktuppgifter i formuläret nedan. Det är viktigt att du fyller i en
      e-postadress som du kontrollerar ofta då det är via den kanalen vi skickar ut all viktig
      information. Tänk på att e-posten ibland fastnar i skräpposten.
    </p>
    <p class="md:w-1/2 pt-6 md:pr-6">
      Fyll gärna i de frivilliga fälten då det hjälper oss att bättre anpassa våra bostäder till
      efterfrågan och hålla kontakt med er.
    </p>
    <form class="md:w-1/2 grid grid-cols-2 gap-2 md:gap-4 pt-12" @submit="checkForm">
      <div class="flex flex-col">
        <input
          id="firstName"
          v-model="firstName"
          type="text"
          name="firstName"
          placeholder="Förnamn*"
          class="bg-beige-200 p-2 body-sm"
          :class="{ error: errors.includes('firstName') }"
        />
        <p class="body-xs pt-1 error-text" :class="{ error: errors.includes('firstName') }">
          Detta fält kan inte lämnas tomt
        </p>
      </div>
      <div class="flex flex-col">
        <input
          id="lastName"
          v-model="lastName"
          type="text"
          name="lastName"
          placeholder="Efternamn*"
          class="bg-beige-200 p-2 body-sm"
          :class="{ error: errors.includes('lastName') }"
        />
        <p class="body-xs pt-1 error-text" :class="{ error: errors.includes('lastName') }">
          Detta fält kan inte lämnas tomt
        </p>
      </div>
      <div class="flex flex-col">
        <input
          id="email"
          v-model="email"
          type="text"
          name="email"
          placeholder="Mejladress*"
          class="bg-beige-200 p-2 body-sm"
          :class="{ error: errors.includes('email') }"
        />
        <p class="body-xs pt-1 error-text" :class="{ error: errors.includes('email') }">
          Detta fält kan inte lämnas tomt
        </p>
      </div>
      <div class="flex flex-col">
        <input
          id="phone"
          v-model="phone"
          type="text"
          name="phone"
          placeholder="Telefonnummer"
          class="bg-beige-200 p-2 body-sm"
        />
        <p class="body-xs pt-1 error-text" :class="{ error: errors.includes('email') }"></p>
      </div>

      <input
        id="year"
        v-model="year"
        type="text"
        name="year"
        placeholder="Födelseår"
        class="bg-beige-200 p-2 body-sm"
      />
      <input
        id="zip"
        v-model="zip"
        type="text"
        name="zip"
        placeholder="Postnummer"
        class="bg-beige-200 p-2 body-sm"
      />
      <span class="col-span-2 flex flex-row">
        <label
          for="consent"
          class="body-xs checkbox-container pr-32 md:pr-0"
          :class="{ 'text-red': errors.includes('email') }"
        >
          Jag har tagit del av informationen om hur mina personuppgifter behandlas.
          <div class="underline body-xs">
            <a href="https://wallinbostad.se/om-personuppgiftsbehandling/" target="_blank"
              >Läs mer om vår personuppgiftshantering</a
            >
          </div>
          <input
            id="consent"
            v-model="consent"
            type="checkbox"
            name="consent"
            value="Jag har tagit del av informationen om hur mina personuppgifter behandlas."
          />
          <span class="checkmark"></span>
        </label>
      </span>
      <span class="col-span-2 pt-6 md:pt-12 pb-16 md:pb-24">
        <button
          class="btn"
          :class="{ 'button--inactive': apiLoading }"
          :disabled="apiLoading"
          type="submit"
        >
          SKICKA INTRESSEANMÄLAN
        </button>
      </span>

      <div>{{ errorMessage }}</div>
    </form>
  </div>
  <div v-if="success" class="w-full md:w-1/2 pb-20">
    <h1 class="heading-md pb-6">Tack för din intresseanmälan</h1>
    <p class="md:pr-6">
      Som anmäld i vårt intresseregister blir du i turordning kontaktad av ansvarig
      fastighetsmäklare. Du är även den första som får du besked om projektets utveckling, nyheter
      och när det är dags för försäljningsstart.
    </p>
    <p class="pt-6 md:pr-6 pb-12 md:pb-20">
      Du kommer nu få en bekräftelse till den mailadress som du har angivit. Tänk på att mailen kan
      hamna i skräpposten.
    </p>
    <a href="https://www.ersta25.se/"><button class="btn">tillbaka till ersta/25</button></a>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      info: null,
      errorMessage: null,
      apiLoading: false,
      consent: false,
      errors: [],
      projectId: null,
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      year: '',
      success: '',
      activeClass: 'active',
      location: '',
      referrer: '',
      zip: ''
    }
  },
  methods: {
    checkForm(e) {
      e.preventDefault()

      this.apiLoading = true

      this.errors = []

      if (!this.email) {
        this.errors.push('email')
      }

      if (!this.firstName) {
        this.errors.push('firstName')
      }

      if (!this.firstName) {
        this.errors.push('lastName')
      }

      if (!this.consent) {
        this.errors.push('consent')
      }

      if (!this.errors.length) {
        this.submitForm(e)
      } else {
        setTimeout(() => {
          this.apiLoading = false
        }, 600)
      }
    },
    submitForm(e) {
      e.preventDefault()

      const data = {
        email: this.email,
        fullName: this.firstName + ' ' + this.lastName,
        projectId: 14,
        phone: this.phone,
        propertyId: 259,
        birthYear: this.year,
        zip: this.zip,
        referrer: document.referrer,
        location: window.location.href
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvaG15LWZvcm0ifQ._okybOBNTGT4G27t63QmbJPEk__x8GgRC8xnDENHmb8',
        Accept: 'application/json'
      }
      axios
        .post('https://wallinbostad.rebla.se/api/v1/interest-signups', data, { headers })
        .then((response) => {
          this.projectId = response.data.id
          this.success = 'Data saved successfully'
        })
        .catch((error) => {
          this.errorMessage = 'Något gick fel, försök igen'
        })
    }
  }
}
</script>
<style>
.successWrapper {
  display: none;
}

.successWrapper.success {
  display: block;
}
input:focus {
  outline: 1px solid black;
}

input.error {
  border: 1.5px solid #c90a0a;
}

.error-text {
  display: none;
}
.error-text.error {
  display: block;
  color: #c90a0a;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 52px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  width: 45px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container.text-red .checkmark {
  border: 1.5px solid #c90a0a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: 'X';
  font-size: 30px;
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
</style>
