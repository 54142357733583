<template>
  <div class="first-list hover:bg-beige" :class="{ active: isActive, 'last-list': isLast }">
    <div @click="toggleContent">
      <div class="wrapper px-5 md:px-12 py-6 flex flex-row justify-between items-center">
        <h3 class="heading-md">{{ item.heading }}</h3>
        <span :class="{ 'svg-rotate': isActive }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="26"
            viewBox="0 0 50 26"
            fill="none"
          >
            <line
              y1="-1"
              x2="34.1246"
              y2="-1"
              transform="matrix(-0.710887 0.703306 -0.710887 -0.703306 48.2587 0)"
              stroke="black"
              stroke-width="2"
            />
            <line
              y1="-1"
              x2="34.1246"
              y2="-1"
              transform="matrix(0.710887 0.703306 0.710887 -0.703306 2 0)"
              stroke="black"
              stroke-width="2"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div class="content" :class="{ active: isActive, 'last-content': isLast }">
    <div
      v-if="item.content_option === 'img-grid'"
      class="wrapper px-5 md:px-12 flex flex-col gap-6 py-6 md:py-12"
    >
      <p>{{ item.small_heading }}</p>
      <p class="md:w-1/2">{{ item.text }}</p>
      <div class="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-6 pt-6 md:pt-16">
        <div v-for="(img, i) in item.images" :key="i">
          <img :src="img.img.url" :alt="img.img.alt" />
        </div>
      </div>
    </div>
    <div v-if="item.content_option === 'list'" class="pb-6 md:pb-16 border-black">
      <div class="md:ml-[25%] xl:ml-[40%]">
        <div v-for="(row, i) in item.rows" :key="i" class="items-wrapper flex flex-row">
          <div class="w-1/2 md:w-full md:max-w-[320px]">
            <p v-if="row.text_left" class="pl-5 md:pl-0 items py-2 md:py-4 uppercase">
              {{ row.text_left }}
            </p>
          </div>
          <div class="w-1/2 md:w-full">
            <div v-if="row.text_right" class="items py-2 md:py-4 pr-5 md:pr-0">
              <p>{{ row.text_right }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.content_option === 'columns'" class="md:pb-12">
      <div v-for="(col, i) in item.columns" :key="i" class="md:pb-12 border-t-[0.5px] border-black">
        <div class="wrapper px-5 md:px-12 grid md:grid-cols-2 gap-16 pt-8">
          <div class="pr-4">
            <h2 v-if="col.heading_left" class="uppercase pb-6">{{ col.heading_left }}</h2>
            <div v-if="col.content_left" class="wysiwyg" v-html="col.content_left"></div>
          </div>
          <div class="pr-4">
            <h2 v-if="col.heading_right" class="uppercase pb-6">{{ col.heading_right }}</h2>
            <div v-if="col.content_right" class="wysiwyg" v-html="col.content_right"></div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="item.content_option === 'image'"
      class="pb-12 wrapper md:px-12 pt-6 md:pt-20 pb-6 md:pb-32"
    >
      <img
        v-if="item.image"
        :src="item.image.url"
        :alt="item.image.alt"
        lazyloading
        class="w-full md:pr-6"
      />
    </div>
    <div v-if="item.content_option === 'process'" class="wrapper px-5 md:px-12 pt-20 pb-32">
      <the-process :items="item.process"></the-process>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item', 'index', 'totalItems'],
  data() {
    return {
      isActive: false,
      isLast: false
    }
  },
  mounted() {
    this.isLast = this.index === this.totalItems - 1
  },
  methods: {
    toggleContent: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style>
.first-list {
  border-bottom: 1px black solid; /* Standardfärg för first-list */
  border-top: 2px #ffffff solid;
}

.first-list:hover {
  border-top: 2px #e5e4e0 solid;
}

.first-list.active {
  background-color: #e5e4e0;
}

.first-list.active {
  border-top: 2px black solid;
}

.first-list.last-list {
  border-bottom: 0;
}
.last-content {
  border-top: 1px solid black;
}
.content.active.last-content {
  border-bottom: 0;
}

.first-list:first-child {
  border-top: 3px black solid;
}

.content {
  display: none;
}

.content.active {
  display: block;
  border-bottom: 1px solid black;
}

.items-wrapper:first-child .items {
  border: 0px;
}

.items-wrapper .items {
  border-top: 0.5px solid black;
}
.svg-rotate {
  transform: rotate(180deg);
}

svg {
  @apply max-w-[26px] md:max-w-[46px];
}
</style>
