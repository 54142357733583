<script setup lang="ts">
interface Props {
  title: string
}
const props = defineProps<Props>()
</script>

<template>
  <h1>{{ props.title ?? 'Hello' }}</h1>
</template>
<style></style>
