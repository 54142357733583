<template>
  <div>
    <div
      v-for="(item, i) in items"
      :key="i"
      :ref="'item_' + i"
      :class="['grid', 'grid-cols-12', 'pl-2', 'fade-in', { 'fade-in-visible': isVisible(i) }]"
      class="process-wrap"
    >
      <div class="col-span-12 grid grid-cols-12 soft-line relative pl-0">
        <div class="time-line col-span-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            class="absolute -top-[6px] -left-[8px]"
          >
            <circle cx="7.5" cy="7.5" r="7.5" transform="rotate(-90 7.5 7.5)" fill="#514F4F" />
          </svg>
        </div>
        <div class="col-span-8 md:col-span-6 pl-4 md:pl-6 -mt-2 md:-mt-4">
          <h2 v-if="item.title" class="uppercase pb-4 md:pb-6">{{ item.title }}</h2>
        </div>
        <div class="col-span-12 grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-12">
            <div class="col-span-4 flex flex-row justify-end">
              <p v-if="item.datum" class="" v-html="item.datum"></p>
            </div>
            <div
              v-if="item.text"
              class="wysiwyg col-span-8 pl-4 md:pl-6 pb-20 md:pb-28"
              v-html="item.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll() // Initial check to set the first item as visible
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    isVisible(index) {
      const refName = 'item_' + index
      if (!this.$refs[refName]) return false

      const element = Array.isArray(this.$refs[refName])
        ? this.$refs[refName][0]
        : this.$refs[refName]
      if (!element) return false

      const rect = element.getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const scrollDistance = windowHeight * 0.2

      return rect.top <= windowHeight - scrollDistance
    },
    handleScroll() {
      for (let index = 0; index < this.items.length; index++) {
        const refName = 'item_' + index
        const element = Array.isArray(this.$refs[refName])
          ? this.$refs[refName][0]
          : this.$refs[refName]

        if (index === 0) {
          // Always make the first item visible
          if (!element.classList.contains('fade-in-visible')) {
            element.classList.add('fade-in-visible')
            this.animateLine(element)
          }
        } else {
          // Only reveal the next item if the previous one is fully visible
          const prevRefName = 'item_' + (index - 1)
          const prevElement = Array.isArray(this.$refs[prevRefName])
            ? this.$refs[prevRefName][0]
            : this.$refs[prevRefName]

          if (
            prevElement &&
            prevElement.classList.contains('fade-in-visible') &&
            this.isVisible(index)
          ) {
            if (!element.classList.contains('fade-in-visible')) {
              element.classList.add('fade-in-visible')
              this.animateLine(element)
            }
          }
        }
      }
    },
    animateLine(element) {
      const line = element.querySelector('.soft-line')
      if (!line) return

      line.style.height = '100%'
    }
  }
}
</script>

<style scoped>
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.fade-in-visible {
  opacity: 1;
}

.time-line {
  border-top: 4px dotted #514f4f;
}

.soft-line {
  border-left: 1px solid #5b5959;
  height: 0; /* Start with height 0 */
  transition: height 1s ease; /* Add transition for height */
}

.process-wrap:last-child .soft-line {
  border-left: none;
}
</style>
