<template>
  <div class="wrapper px-5 md:px-12 grid grid-cols-4 gap-x-4 gap-y-2">
    <div
      v-if="combinedApartments.length"
      class="col-span-4 grid grid-cols-2 gap-8 pb-3"
      @click="toggleActiveAndShowDescription(0)"
    >
      <div :class="['cursor-pointer bg-black text-white', { 'bg-selected-pent': isActive(0) }]">
        <div class="flex flex-row items-center border-b-[2px] border-white py-4 px-6">
          <p>{{ combinedApartments[0].rooms }} ROK</p>
          <span class="w-[10px] bg-white h-[2px] mx-2"></span>
          <p>VÅN {{ combinedApartments[0].floor }}</p>
          <span class="w-[10px] bg-white h-[2px] mx-2"></span>
          <p>{{ formatSize(combinedApartments[0].size) }} KVM</p>
        </div>
        <ul class="usp grid grid-cols-2">
          <li
            v-for="(item, index) in combinedApartments[0].description.split(',')"
            :key="index"
            class="py-3 px-6 border-t-[0.5px] border-white"
          >
            {{ item.trim() }}
          </li>
        </ul>
        <div class="grid grid-cols-2 px-12 pb-12 pt-32">
          <div class="col-span-1 col-start-2 pl-6">
            <img :src="img + '/img/apartment/ersta-1.png'" alt="" class="w-full" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDescriptionVisible(0)" ref="description-0" class="text-white bg-black col-span-4">
      <div class="grid grid-cols-12 border-b-[0.5px] border-white px-12 py-4">
        <p class="col-span-2 uppercase">namn</p>
        <p class="col-span-2 uppercase">rum</p>
        <p class="col-span-2 uppercase">storlek ca.</p>
        <p class="col-span-2 uppercase">våning</p>
        <p class="col-span-2 uppercase">avgift</p>
        <p class="col-span-2 uppercase">pris</p>
      </div>
      <div class="grid grid-cols-12 px-12 border-b-[3px] border-white py-4">
        <p class="col-span-2">{{ combinedApartments[0].tagline }}/25</p>
        <p class="col-span-2">{{ combinedApartments[0].rooms }} rok</p>
        <p class="col-span-2">{{ formatSize(combinedApartments[0].size) }} kvm</p>
        <p class="col-span-2">{{ combinedApartments[0].floor }}</p>
        <p class="col-span-2">Publiceras senare</p>
        <p class="col-span-2">Pris vid förfrågan</p>
      </div>
      <div class="px-12 pt-12 penthouse">
        <div class="border-b-[3px] border-white pb-24">
          <h3 class="uppercase pb-6">Om bostaden 1/25</h3>
          <div class="grid grid-cols-2 gap-8 pb-16">
            <p>
              Hela bostaden har varmt beiga kalk- och lerputsade väggar och ljusa vackra ekgolv.
              Våningens tre sovrum har alla egna, separata badrum inredda med dusch och toalett.
              Stilfulla materialval så som marmorino, terazzo och röd kalksten är exempel från det
              exklusiva masterbadrummet som även har bastu med panel i cederträ och fristående
              badkar. Köket och vardagsrummet möts i en öppen genomarbetad planlösning med
              imponerande utsikt åt samtliga väderstreck.
            </p>
            <p>
              Platsbyggda bokhyllor i våningens bibliotek, ett inbyggt tv-skåp i vardagsrummet täckt
              med dekorativ tapet och en fullt inredd walk-in-closet är exempel på smarta och
              exklusiva lösningar som visar att bostadens alla utrymmen utnyttjas fullt ut med
              skräddarsydda lösningar. Pricken över i:et är den eleganta gaseldstad som blir en
              naturlig social mittpunkt med sin placering mot utsikten över Saltsjön i
              vardagsrummet.
            </p>
          </div>
          <a :href="combinedApartments[0].factsheetUrl" class="link" target="_blank"
            >Ladda ner bofaktablad</a
          >
        </div>
        <div class="">
          <p class="uppercase py-8">Planlösning</p>
          <img
            :src="combinedApartments[0].floorPlanUrl"
            alt="Floor Plan"
            class="border-[0.5px] border-black pb-8"
          />
        </div>
        <div class="pb-24">
          <a href="https://www.erstapenthouse.se/" target="_blank"
            ><button class="btn">penthouse</button></a
          >
        </div>
      </div>
    </div>
    <template v-for="(item, i) in combinedApartments.slice(1)" :key="i">
      <div
        :id="item.tagline"
        class="relative"
        :class="[
          'bg-white card flex flex-col justify-between cursor-pointer',
          { 'bg-selected': isActive(i + 1) },
        ]"
        @click="toggleActiveAndShowDescription(i + 1)"
      >
        <div
          class="pb-8"
          :class="{ 'opacity-35': item.status === 'BOOKED' || item.status === 'SOLD' }"
        >
          <div class="border-b-[3px] border-black flex flex-row pl-4 pt-7 pb-3 items-center w-full">
            <p>{{ item.rooms }} ROK</p>
            <span class="stroke"></span>
            <div v-if="item.floor === '0'" class="col-span-2">
              <p>Entréplan</p>
            </div>
            <div v-else class="col-span-2">VÅN {{ item.floor }}</div>
            <span class="stroke"></span>
            <p>{{ formatSize(item.size) }} KVM</p>
          </div>
          <div v-if="item.description" class="usp">
            <div
              v-for="(tagline, index) in item.description.split(',')"
              :key="index"
              class="tagline px-4 py-3"
            >
              {{ tagline.trim() }}
            </div>
          </div>
        </div>
        <div
          :id="'description-' + (i + 2)"
          ref="description-{{ i + 2 }}"
          class="flex flex-row justify-end pr-4 w-full pt-6 pb-4"
          :class="{ 'opacity-35': item.status === 'BOOKED' || item.status === 'SOLD' }"
        >
          <img
            :src="img + '/img/apartment/ersta-' + item.tagline + '.png'"
            alt="Planritning av bostad"
            class="max-w-[170px]"
          />
        </div>
        <div v-if="item.status === 'SOLD'" class="absolute left-6 bottom-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
          >
            <g clip-path="url(#clip0_2000_11)">
              <g filter="url(#filter0_d_2000_11)">
                <path
                  d="M18.2349 34.8915C27.4166 34.8915 34.8599 27.4233 34.8599 18.2109C34.8599 8.99843 27.4166 1.53027 18.2349 1.53027C9.05313 1.53027 1.60986 8.99843 1.60986 18.2109C1.60986 27.4233 9.05313 34.8915 18.2349 34.8915Z"
                  fill="#BE1622"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_2000_11"
                x="0.189863"
                y="0.110273"
                width="38.93"
                height="39.0411"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="1.42" dy="1.42" />
                <feGaussianBlur stdDeviation="1.42" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.113725 0 0 0 0 0.113725 0 0 0 0 0.105882 0 0 0 0.54 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2000_11"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2000_11"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_2000_11">
                <rect width="38" height="38" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div
        v-if="(i + 1) % 4 === 0 || i === combinedApartments.slice(1).length - 1"
        class="grid grid-cols-4 col-span-4"
      >
        <div
          v-if="isDescriptionVisible(i + 1)"
          class="bg-white col-span-4 border-t-[3px] border-black"
        >
          <div
            v-if="combinedApartments[selectedIndex].status === 'SOLD'"
            class="w-full border-b-[3px] border-black px-12 py-4"
          >
            <p>Denna bostad är såld</p>
          </div>
          <div
            v-if="combinedApartments[selectedIndex].status === 'BOOKED'"
            class="w-full border-b-[3px] border-black px-12 py-4"
          >
            <p>Denna bostad är reserverad</p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 border-b-[0.5px] border-black px-12 py-4"
            :class="{
              'opacity-35':
                combinedApartments[selectedIndex].status === 'BOOKED' ||
                combinedApartments[selectedIndex].status === 'SOLD',
            }"
          >
            <p class="col-span-2 uppercase">namn</p>
            <p class="col-span-2 uppercase">rum</p>
            <p class="col-span-2 uppercase">storlek ca.</p>
            <p class="col-span-2 uppercase">våning</p>
            <p class="col-span-2 uppercase">avgift</p>
            <p class="col-span-2 uppercase">pris</p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 px-12 border-b-[3px] border-black py-4"
            :class="{
              'opacity-35':
                combinedApartments[selectedIndex].status === 'BOOKED' ||
                combinedApartments[selectedIndex].status === 'SOLD',
            }"
          >
            <p class="col-span-2">{{ combinedApartments[selectedIndex].tagline }}/25</p>
            <p class="col-span-2">{{ combinedApartments[selectedIndex].rooms }} rok</p>
            <p class="col-span-2">{{ formatSize(combinedApartments[selectedIndex].size) }} kvm</p>
            <p v-if="combinedApartments[selectedIndex].floor === '0'" class="col-span-2">
              Entréplan
            </p>
            <p v-else class="col-span-2">{{ combinedApartments[selectedIndex].floor }}</p>
            <p v-if="combinedApartments[selectedIndex].status !== 'SOLD'" class="col-span-2">
              {{ combinedApartments[selectedIndex].monthlyFee }} kr
            </p>
            <p v-if="combinedApartments[selectedIndex].status !== 'SOLD'" class="col-span-2">
              {{ formattedPrice }} kr
            </p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 px-12 pb-16"
            :class="{
              'opacity-35':
                combinedApartments[selectedIndex].status === 'BOOKED' ||
                combinedApartments[selectedIndex].status === 'SOLD',
            }"
          >
            <div class="col-span-8">
              <p class="uppercase py-8">Bokfaktablad</p>
              <img
                :src="combinedApartments[selectedIndex].floorPlanUrl"
                alt="Floor Plan"
                class="border-[0.5px] border-black pb-8 md:pb-12"
              />
              <div v-if="combinedApartments[selectedIndex].image" class="w-full py-16">
                <p class="uppercase pb-10">Utsikt</p>
                <img
                  :src="combinedApartments[selectedIndex].image.url"
                  :alt="combinedApartments[selectedIndex].image.url"
                />
              </div>
              <div class="pt-16">
                <a href="https://ersta25.se/intresseanmalan/">
                  <button class="btn">intresseanmälan</button>
                </a>
              </div>
            </div>
            <div class="col-span-4">
              <p class="uppercase py-8">
                Om bostaden {{ combinedApartments[selectedIndex].tagline }}/25
              </p>
              <div
                v-if="combinedApartments[selectedIndex].about"
                class="wysiwyg pb-12"
                v-html="combinedApartments[selectedIndex].about"
              ></div>
              <a :href="combinedApartments[selectedIndex].factsheetUrl" class="link" target="_blank"
                >Ladda ner bofaktablad</a
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['img', 'apartments'],
  data() {
    return {
      apiApartments: [],
      selectedIndex: null,
      activeIndex: null
    }
  },
  computed: {
    combinedApartments() {
      return this.apiApartments.map((apiApartment) => {
        const matchedApartment = this.apartments.find(
          (apartment) => apartment.tagline === apiApartment.tagline
        )
        return { ...apiApartment, ...matchedApartment }
      })
    },
    formattedPrice() {
      if (this.selectedIndex !== null && this.combinedApartments[this.selectedIndex]) {
        const price = this.combinedApartments[this.selectedIndex].price
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return ''
    }
  },
  mounted() {
    this.fetchData()
    const savedTagline = sessionStorage.getItem('selectedApartmentTagline')
    if (savedTagline) {
      this.$nextTick(() => {
        this.scrollToTagline(savedTagline)
        this.toggleActiveAndShowDescription(savedTagline - 1)
      })
    }
  },
  methods: {
    fetchData() {
      const token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJvaG15LWZvcm0ifQ._okybOBNTGT4G27t63QmbJPEk__x8GgRC8xnDENHmb8'
      axios
        .get('https://wallinbostad.rebla.se/api/v1/projects/14', {
          headers: {
            Authorization: `Bearer ${token}`,
            token: token
          }
        })
        .then((response) => {
          this.apiApartments = response.data.properties || []
          this.apiApartments.sort((a, b) => Number(a.tagline) - Number(b.tagline)) // Sort the data here
        })
        .catch((error) => {
          console.error('Error fetching data', error)
        })
    },
    toggleActive(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
    isActive(index) {
      return this.activeIndex === index
    },
    showDescription(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = null
      } else {
        this.selectedIndex = index
        this.$nextTick(() => {
          this.scrollToDescription(index)
        })
      }
    },
    isDescriptionVisible(index) {
      if (index === 0) {
        return this.selectedIndex === 0
      } else {
        const isEven = index % 4 === 0
        const isFirstInPair = index === 1 || index % 4 === 1
        return (
          (this.selectedIndex !== null &&
            isEven &&
            Math.floor((this.selectedIndex - 1) / 4) === Math.floor((index - 1) / 4)) ||
          (!isEven &&
            isFirstInPair &&
            Math.floor((this.selectedIndex - 1) / 4) === Math.floor((index - 1) / 4))
        )
      }
    },
    scrollToDescription(index) {
      const element = document.getElementById('description-' + (this.selectedIndex + 1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    },
    scrollToTagline(tagline) {
      this.$nextTick(() => {
        const element = document.getElementById(tagline)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        } else {
          // Om elementet inte finns ännu, försök igen efter en kort fördröjning
          setTimeout(() => {
            const retryElement = document.getElementById(tagline)
            if (retryElement) {
              retryElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
          }, 300)
        }
      })
    },
    toggleActiveAndShowDescription(index) {
      this.toggleActive(index)
      this.showDescription(index)
    },
    formatSize(size) {
      return Math.floor(size)
    }
  }
}
</script>

<style scoped>
.stroke {
  @apply w-[8px] bg-black h-[2px] mx-1;
}
.card {
  min-height: 490px;
}
.bg-selected {
  @apply bg-beige;
}
.bg-selected-pent {
  background-color: #3f3f3f;
}
</style>
