<template>
  <div class="wrapper px-5 md:px-12 grid grid-cols-6 md:grid-cols-12 gap-2 lg:gap-4 pb-12 lg:pb-32">
    <div
      v-for="(image, i) in list"
      :key="i"
      :class="image.grid_cols"
      class="hidden md:block cursor-pointer max-h-[320px]"
      @click="openModal(i)"
    >
      <img
        :src="image.image.url"
        :alt="image.image.alt"
        class="object-cover object-center h-full w-full"
        width="450"
        height="320"
      />
    </div>
    <div
      v-for="(image, i) in list"
      :key="i"
      :class="image.grid_cols_sm"
      class="md:hidden cursor-pointer max-h-[320px]"
      @click="openModal(i)"
    >
      <img
        :src="image.image.url"
        :alt="image.image.alt"
        class="object-cover object-center h-full w-full"
        width="450"
        height="320"
      />
    </div>

    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
    >
      <div class="relative w-full max-w-[1000px] bg-white py-6 md:py-12 md:px-12">
        <button class="absolute -top-2 md:top-0 right-0 m-4 text-black z-10" @click="closeModal">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 60">
              <line
                id="Line 182"
                x1="1.25008"
                y1="1.11028"
                x2="39.5837"
                y2="39.4439"
                stroke="black"
                stroke-width="2"
              />
              <line
                id="Line 183"
                x1="39.0411"
                y1="1.70711"
                x2="0.707493"
                y2="40.0407"
                stroke="black"
                stroke-width="2"
              />
            </g>
          </svg>
        </button>
        <swiper
          ref="swiper"
          :initial-slide="currentSlide"
          :slides-per-view="1"
          :space-between="30"
          :navigation="true"
          :modules="modules"
          :loop="true"
          class="imageSlider"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="(image, i) in list"
            :key="i"
            class="flex justify-center items-center"
          >
            <img
              :src="image.image.url"
              :alt="image.image.alt"
              class="max-w-full max-h-full object-contain md:p-12"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['list'],
  setup() {
    return {
      modules: [Navigation]
    }
  },
  data() {
    return {
      isModalOpen: false,
      currentSlide: 0,
      swiperInstance: null
    }
  },
  methods: {
    openModal(index) {
      this.currentSlide = index
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    onSwiper(swiper) {
      this.swiperInstance = swiper
      this.$nextTick(() => {
        if (this.swiperInstance) {
          this.swiperInstance.slideTo(this.currentSlide, 0)
        }
      })
    }
  }
}
</script>

<style scoped>
/* Lägg till eventuell anpassad styling här */
.swiper-button-next,
.swiper-button-prev {
  color: black;
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 100;
  cursor: pointer;
}

.swiper-button-next {
  right: 20px;
  z-index: 10;
}

.swiper-button-prev {
  left: 20px;
  z-index: 10;
}

.imageSlider {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.fixed.inset-0 {
  background-color: rgba(0, 0, 0, 0.75);
}
</style>
