<template>
  <div :id="'lght' + object.tagline" class="info-heading border-b-[0.5px] border-black">
    <div class="wrapper grid grid-cols-12 py-4 px-5 md:px-12">
      <div class="col-span-1 flex flex-col justify-center">
        <!-- <div class="green-cirkle rounded-full"></div> -->
        <span class="line"></span>
      </div>
      <div class="col-span-2">{{ object.tagline }}/25</div>
      <div class="col-span-1">
        {{ object.rooms }}
      </div>
      <div v-if="object.floor === 0" class="col-span-2">
        <p>Entréplan</p>
      </div>
      <div v-else class="col-span-2">
        {{ object.floor }}
      </div>
      <div class="col-span-2">{{ object.size.toFixed(0) }} kvm</div>
      <div class="col-span-2 flex flex-col justify-center">
        <!-- {{ object.monthlyFee }} -->
        <span class="line"></span>
      </div>
      <div class="col-span-2 flex flex-col justify-center">
        <!-- {{ object.price }} -->
        <span class="line"></span>
      </div>
    </div>
  </div>
  <div class="info bg-beige-200 col-span-12" :class="{ active: isActive }">
    <div v-if="object.description">
      <div class="border-b-[0.5px] border-black border-t-2">
        <p class="wrapper uppercase py-4 px-5 md:px-12">Egenskaper</p>
      </div>

      <div
        v-for="info in object.description.split(',')"
        :key="info"
        class="border-b-[0.5px] border-black py-4 px-5 md:px-12"
      >
        <p class="wrapper px-5 md:px-12">{{ info }}</p>
      </div>
    </div>
    <div v-else>
      <div class="border-b-[0.5px] border-t-2 border-black">
        <p class="wrapper uppercase py-4 px-5 md:px-12">Egenskaper</p>
      </div>
      <div class="border-b-[0.5px] border-black">
        <p class="wrapper py-4 px-5 md:px-12">Beskrivning saknas</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['object'],
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    showInfo: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style>
.info {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.info.active {
  height: 380px;
  transition: height 0.5s;
}

.info-heading.active {
  -webkit-box-shadow: inset 0 2px 0 black;
}

.inside-border {
  box-shadow: 0px -3px black;
}
</style>
