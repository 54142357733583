<template>
  <div class="modal">
    <div class="modal-content bg-white flex flex-col items-center px-4 md:px-6 md:px-0">
      <div class="w-full flex flex-row justify-end pt-2 md:pt-6">
        <span class="close cursor-pointer" @click="$emit('close')">
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 60">
              <line
                id="Line 182"
                x1="1.25008"
                y1="1.11028"
                x2="39.5837"
                y2="39.4439"
                stroke="black"
                stroke-width="2"
              />
              <line
                id="Line 183"
                x1="39.0411"
                y1="1.70711"
                x2="0.707493"
                y2="40.0407"
                stroke="black"
                stroke-width="2"
              />
            </g>
          </svg>
        </span>
      </div>
      <div class="pt-2 pb-8 lg:pt-12 md:pb-16">
        <img v-if="floorPlanUrl" :src="floorPlanUrl" alt="Floor Plan" class="pb-8 md:pb-12" />
        <a v-if="factsheetUrl" :href="factsheetUrl" class="link" target="_blank"
          >Ladda ned faktablad</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['factsheetUrl', 'floorPlanUrl'],
  data() {
    return {}
  }
}
</script>
