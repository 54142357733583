<template>
  <div class="wrapper px-5 md:px-12 grid grid-cols-4 gap-x-4 gap-y-2">
    <div class="col-span-4 grid grid-cols-2 gap-8 pb-3" @click="toggleActiveAndShowDescription(0)">
      <div :class="['cursor-pointer bg-black text-white', { 'bg-selected-pent': isActive(0) }]">
        <div class="flex flex-row items-center border-b-[2px] border-white py-4 px-6">
          <p>{{ apartments[0].rooms }} ROK</p>
          <span class="w-[10px] bg-white h-[2px] mx-2"></span>
          <p>VÅN {{ apartments[0].floor }}</p>
          <span class="w-[10px] bg-white h-[2px] mx-2"></span>
          <p>{{ apartments[0].size }} KVM</p>
        </div>
        <ul class="usp grid grid-cols-2">
          <li
            v-for="item in apartments[0].bullet_list"
            :key="item"
            class="py-3 px-6 border-t-[0.5px] border-white"
          >
            {{ item.text }}
          </li>
        </ul>
        <div class="grid grid-cols-2 px-12 pb-12 pt-32">
          <div class="col-span-1 col-start-2 pl-6">
            <img :src="img + '/img/apartment/ersta-1.png'" alt="" class="w-full" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDescriptionVisible(0)" ref="description-0" class="text-white bg-black col-span-4">
      <div class="grid grid-cols-12 border-b-[0.5px] border-white px-12 py-4">
        <p class="col-span-2 uppercase">namn</p>
        <p class="col-span-2 uppercase">rum</p>
        <p class="col-span-2 uppercase">storlek ca.</p>
        <p class="col-span-2 uppercase">våning</p>
        <p class="col-span-2 uppercase">avgift</p>
        <p class="col-span-2 uppercase">pris</p>
      </div>
      <div class="grid grid-cols-12 px-12 border-b-[3px] border-white py-4">
        <p class="col-span-2">{{ apartments[0].tagline }}/25</p>
        <p class="col-span-2">{{ apartments[0].rooms }} rok</p>
        <p class="col-span-2">{{ apartments[0].size }} kvm</p>
        <p class="col-span-2">{{ apartments[0].floor }}</p>
        <p v-if="apartments[0].fee" class="col-span-2">{{ apartments[0].fee }}</p>
        <p v-else class="col-span-2">Publiceras senare</p>
        <p class="col-span-2">Pris vid förfrågan</p>
      </div>
      <div class="px-12 pt-12 penthouse">
        <div class="border-b-[3px] border-white pb-24">
          <h3 class="uppercase pb-6">Om bostaden 1/25</h3>
          <div class="grid grid-cols-2 gap-8 pb-16">
            <p>
              Hela bostaden har varmt beiga kalk- och lerputsade väggar och ljusa vackra ekgolv.
              Våningens tre sovrum har alla egna, separata badrum inredda med dusch och toalett.
              Stilfulla materialval så som marmorino, terazzo och röd kalksten är exempel från det
              exklusiva masterbadrummet som även har bastu med panel i cederträ och fristående
              badkar. Köket och vardagsrummet möts i en öppen genomarbetad planlösning med
              imponerande utsikt åt samtliga väderstreck.
            </p>
            <p>
              Platsbyggda bokhyllor i våningens bibliotek, ett inbyggt tv-skåp i vardagsrummet täckt
              med dekorativ tapet och en fullt inredd walk-in-closet är exempel på smarta och
              exklusiva lösningar som visar att bostadens alla utrymmen utnyttjas fullt ut med
              skräddarsydda lösningar. Pricken över i:et är den eleganta gaseldstad som blir en
              naturlig social mittpunkt med sin placering mot utsikten över Saltsjön i
              vardagsrummet.
            </p>
          </div>
          <a :href="apartments[0].sheet.url" class="link" target="_blank">Ladda ner bofaktablad</a>
        </div>
        <div class="">
          <p class="uppercase py-8">Planlösning</p>
          <img
            :src="apartments[0].floorplan.url"
            alt="Floor Plan"
            class="border-[0.5px] border-black pb-8"
          />
        </div>
        <div v-if="apartments[selectedIndex].image" class="w-full py-16">
          <p class="uppercase pb-10">Utsikt</p>
          <img
            :src="apartments[selectedIndex].image.url"
            :alt="apartments[selectedIndex].image.url"
          />
        </div>
        <div class="pb-24">
          <a href="https://www.erstapenthouse.se/" target="_blank"
            ><button class="btn">penthouse</button></a
          >
        </div>
      </div>
    </div>
    <template v-for="(item, i) in apartments.slice(1)" :key="i">
      <div
        :class="[
          'bg-white card flex flex-col justify-between cursor-pointer',
          { 'bg-selected': isActive(i + 1) },
        ]"
        @click="toggleActiveAndShowDescription(i + 1)"
      >
        <div class="pb-8">
          <div class="border-b-[3px] border-black flex flex-row pl-4 pt-7 pb-3 items-center w-full">
            <p>{{ item.rooms }} ROK</p>
            <span class="stroke"></span>
            <div v-if="item.floor === '0'" class="col-span-2">
              <p>Entréplan</p>
            </div>
            <div v-else class="col-span-2">VÅN {{ item.floor }}</div>
            <span class="stroke"></span>
            <p>{{ item.size }} KVM</p>
          </div>
          <div v-if="item.bullet_list" class="usp">
            <div v-for="tagline in item.bullet_list" :key="tagline" class="tagline px-4 py-3">
              {{ tagline.text }}
            </div>
          </div>
        </div>
        <div
          :id="'description-' + (i + 2)"
          ref="description-{{ i + 2 }}"
          class="flex flex-row justify-end pr-4 w-full pt-6 pb-4"
        >
          <img
            :src="img + '/img/apartment/ersta-' + item.tagline + '.png'"
            alt="Planritning av bostad"
            class="max-w-[170px]"
          />
        </div>
      </div>
      <div
        v-if="(i + 1) % 4 === 0 || i === apartments.slice(1).length - 1"
        class="grid grid-cols-4 col-span-4"
      >
        <div
          v-if="isDescriptionVisible(i + 1)"
          class="bg-white col-span-4 border-t-[3px] border-black"
        >
          <div
            v-if="apartments[selectedIndex].is_sold"
            class="w-full border-b-[3px] border-black px-12 py-4"
          >
            <p>DENNA BOSTAD ÄR BOKAD</p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 border-b-[0.5px] border-black px-12 py-4"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <p class="col-span-2 uppercase">namn</p>
            <p class="col-span-2 uppercase">rum</p>
            <p class="col-span-2 uppercase">storlek ca.</p>
            <p class="col-span-2 uppercase">våning</p>
            <p class="col-span-2 uppercase">avgift</p>
            <p class="col-span-2 uppercase">pris</p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 px-12 border-b-[3px] border-black py-4"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <p class="col-span-2">{{ apartments[selectedIndex].tagline }}/25</p>
            <p class="col-span-2">{{ apartments[selectedIndex].rooms }} rok</p>
            <p class="col-span-2">{{ apartments[selectedIndex].size }} kvm</p>
            <p v-if="apartments[selectedIndex].floor === '0'" class="col-span-2">Entréplan</p>
            <p v-else class="col-span-2">
              {{ apartments[selectedIndex].floor }}
            </p>
            <p v-if="apartments[selectedIndex].fee" class="col-span-2">
              {{ apartments[selectedIndex].fee }}
            </p>
            <p v-else class="col-span-2">Publiceras senare</p>
            <p class="col-span-2">{{ apartments[selectedIndex].price }}</p>
          </div>
          <div
            class="grid grid-cols-12 gap-4 px-12 pb-16"
            :class="{ 'opacity-35': apartments[selectedIndex].is_sold }"
          >
            <div class="col-span-8">
              <p class="uppercase py-8">Bokfaktablad</p>
              <img
                :src="apartments[selectedIndex].floorplan.url"
                alt="Floor Plan"
                class="border-[0.5px] border-black pb-8 md:pb-12"
              />
              <div v-if="apartments[selectedIndex].image" class="w-full py-16">
                <p class="uppercase pb-10">Utsikt</p>
                <img
                  :src="apartments[selectedIndex].image.url"
                  :alt="apartments[selectedIndex].image.url"
                />
              </div>
              <div class="pt-16">
                <a href="https://ersta25.se/intresseanmalan/">
                  <button class="btn">intresseanmälan</button>
                </a>
              </div>
            </div>
            <div class="col-span-4">
              <p class="uppercase py-8">Om bostaden {{ apartments[selectedIndex].tagline }}/25</p>
              <div
                v-if="apartments[selectedIndex].about"
                class="wysiwyg pb-12"
                v-html="apartments[selectedIndex].about"
              ></div>
              <a :href="apartments[selectedIndex].sheet.url" class="link" target="_blank"
                >Ladda ner bofaktablad</a
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['img', 'apartments'],
  data() {
    return {
      selectedIndex: null,
      activeIndex: null
    }
  },
  mounted() {
    const savedIndex = sessionStorage.getItem('selectedApartmentIndex')
    if (savedIndex !== null) {
      this.selectedIndex = parseInt(savedIndex)
      this.$nextTick(() => {
        this.scrollToDescription(this.selectedIndex)
      })
    }
  },
  methods: {
    toggleActive(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
    isActive(index) {
      return this.activeIndex === index
    },
    showDescription(index) {
      if (this.selectedIndex === index) {
        this.selectedIndex = null
      } else {
        this.selectedIndex = index
        this.$nextTick(() => {
          this.scrollToDescription(index)
        })
      }
    },
    isDescriptionVisible(index) {
      if (index === 0) {
        return this.selectedIndex === 0
      } else {
        const isEven = index % 4 === 0
        const isFirstInPair = index === 1 || index % 4 === 1
        return (
          (this.selectedIndex !== null &&
            isEven &&
            Math.floor((this.selectedIndex - 1) / 4) === Math.floor((index - 1) / 4)) ||
          (!isEven &&
            isFirstInPair &&
            Math.floor((this.selectedIndex - 1) / 4) === Math.floor((index - 1) / 4))
        )
      }
    },
    scrollToDescription(index) {
      const element = document.getElementById('description-' + (this.selectedIndex + 1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    },
    toggleActiveAndShowDescription(index) {
      this.toggleActive(index)
      this.showDescription(index)
    },
    getSelectedTagline() {
      const selectedIndex = parseInt(sessionStorage.getItem('selectedApartmentIndex'))
      if (!isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < this.apartments.length) {
        return this.apartments[selectedIndex].tagline
      }
      return ''
    }
  }
}
</script>

<style scoped>
.stroke {
  @apply w-[8px] bg-black h-[2px] mx-1;
}
.card {
  min-height: 490px;
}
.bg-selected {
  @apply bg-beige;
}
.bg-selected-pent {
  background-color: #3f3f3f;
}
</style>
