<template>
  <div
    class="border-t-[3px] md:border-t-none border-black cursor-pointer flex flex-col"
    @click="showInfo()"
  >
    <div
      class="py-2 px-2 bg-beige-200 flex flex-row items-center justify-between w-full"
      :class="{ 'bg-beige-600': isActive }"
    >
      <p>LGH. {{ object.tagline }}/25</p>
      <div class="button-up" :class="{ active: isActive }"></div>
      <div class="button-down" :class="{ hidden: isActive }"></div>
    </div>

    <div class="bg-beige-600 info-sm" :class="{ active: isActive }">
      <span
        class="border-t-[0.5px] border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2"
      >
        <p>status</p>
        <p class="flex flex-col justify-center"><span class="line"></span></p>
      </span>
      <span class="border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2">
        <p>rum</p>
        <p>{{ object.rooms }}</p>
      </span>
      <span class="border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2">
        <p>vån.</p>
        <p>{{ object.floor }}</p>
      </span>
      <span class="border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2">
        <p>strl.</p>
        <p>{{ object.size.toFixed(0) }}</p>
      </span>
      <span class="border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2">
        <p>avgift</p>
        <p class="flex flex-col justify-center"><span class="line"></span></p>
      </span>
      <span class="border-b-[0.5px] uppercase border-black py-2 px-2 grid grid-cols-2 gap-2">
        <p>pris</p>
        <p class="flex flex-col justify-center"><span class="line"></span></p>
      </span>

      <div v-if="object.description">
        <p class="border-t-2 border-b-[0.5px] border-black py-2 px-2 uppercase">egenskaper</p>
        <p
          v-for="info in object.description.split(',')"
          :key="info"
          class="border-b-[0.5px] border-black py-2 px-2 normal-case"
        >
          {{ info }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['object'],
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    showInfo: function () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style>
.info-sm {
  display: none;
}
.info-sm.active {
  display: block;
}

.button-up {
  display: none;
}

.button-up.active {
  display: block;
}

.button-up,
.button-down {
  position: relative;
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s linear;
}

.button-up::after,
.button-down::after {
  content: '';
  position: absolute;
  right: 5px;
  z-index: 11;
  display: block;
  width: 15px;
  height: 15px;
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
}

.button-up::after {
  top: 0px;
  transform: rotate(45deg);
}

.button-down::after {
  top: -10px;
  transform: rotate(225deg);
}
</style>
