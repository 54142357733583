<template>
  <div class="flex flex-col accordion">
    <accordion-item
      v-for="(item, index) in list"
      :key="index"
      :item="item"
      :index="index"
      :total-items="list.length"
    >
    </accordion-item>
  </div>
</template>

<script>
export default {
  props: ['list']
}
</script>
