<template>
  <swiper
    :slides-per-view="'auto'"
    :space-between="30"
    :navigation="true"
    :modules="modules"
    :loop="true"
    :breakpoints="{
      '@0.00': {
        spaceBetween: 10,
      },
      '@0.75': {
        spaceBetween: 20,
      },
      '@1.00': {
        spaceBetween: 30,
      },
      '@1.50': {
        spaceBetween: 30,
      },
    }"
    class="imageSlider big-slider"
  >
    <swiper-slide v-for="(object, i) in list" :key="object + i">
      <img :src="object.image.url" alt="" class="image" lazyloading />
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'

import 'swiper/css/navigation'

import { Navigation } from 'swiper/modules'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['list'],
  setup() {
    return {
      modules: [Navigation]
    }
  }
}
</script>
